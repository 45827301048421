export function addTelegramButton() {
  if (!document) {
    return;
  }

  const login = 'sofiagranit';

  const linkTag = document.createElement('a');
  linkTag.setAttribute('href', `https://t.me/${login}`);
  linkTag.setAttribute('target', '_blank');
  linkTag.setAttribute('style', 'position: fixed; bottom: 70px; right: 20px;');

  const imgTag = document.createElement('img');
  imgTag.setAttribute('src', '/img/telegram.svg');
  imgTag.setAttribute('style', 'width: 49px; height: 49px;');

  linkTag.appendChild(imgTag);

  document.body.appendChild(linkTag);
}
