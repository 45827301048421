<template>
  <footer class="footer">
    <div class="container footer__container">
      <div class="footer__logo">
        <NuxtLink to="/"
                  class="footer__logo-link">
          {{ $t('theFooter.title') }}<br />
          <b
             class="footer__title-description">{{ $t('theFooter.description') }}</b>
        </NuxtLink>
      </div>
      <nav class="footer__nav">
        <ul class="footer__nav-list">
          <li class="footer__nav-item"
              v-for="route in routes"
              :key="route.path">
            <NuxtLink :to="route.path"
                      class="footer__nav-link">
              {{ route.navigationTitle }}
            </NuxtLink>
          </li>
        </ul>
        <div class="footer__social">
          <h3 class="footer__social-title">
            {{ $t('theFooter.social') }}
          </h3>
          <ul>
            <li class="footer__social-item"
                v-for="socialIcon in socialIcons"
                :key="socialIcon.id">
              <component :is="socialIcon.component"
                         v-bind="socialIcon" />
            </li>
          </ul>
        </div>
      </nav>
      <div class="footer__contacts">
        <address class="footer__address">{{ address }}</address>
        <div class="footer__work-time">{{ workTime }}</div>
        <div class="footer__phone">
          <ul>
            <li v-for="(phone, index) in contactPhones"
                :key="phone.raw"
                :class="index === 0 ? 'footer__tel1' : ''">
              <a :href="`tel:+7${phone.raw}`"
                 class="decoration-none">{{ phone.formatted }}</a>
            </li>
          </ul>
          <BackCallButton class="btn footer__btn" />
        </div>
      </div>
      <p class="footer__permission">
        {{ $t('theFooter.permission') }}
      </p>
      <div class="footer__scroll-up">
        <a href="#app-header"
           class="footer__scroll-up-link">
          <svg width="14"
               height="26"
               viewbox="0 0 14 26"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M7 26V1M7 1L13 7M7 1L1 7"
                  stroke="#E6E6E6" />
          </svg>
        </a>
      </div>
    </div>
    <div class="footer__copyright">
      <!-- TODO: https://github.com/nuxt-modules/i18n/issues/1833 -->
      СОФИЯ ГРАНИТ © 2020 - {{ currentYear }} | Все права защищены
    </div>
    <client-only>
      <WhatsappButton />
      <TelegramButton />
    </client-only>
  </footer>
</template>

<script setup lang="ts">
import {
  IconVk,
  IconInstagram,
  IIconProps,
} from '../ui';
import { BackCallButton } from '../Popups';
import WhatsappButton from './WhatsappButton.vue';
import TelegramButton from './TelegramButton.vue';

const { routes } = useAppConfig();

const contactsStore = useContactsStore();

const {
  address = '',
  workTime = '',
  phones = [],
  socials = [],
} = contactsStore.data || {};

const contactPhones = useContactPhones(phones);
const currentYear = (new Date()).getFullYear();

const iconsComponentsMap = {
  vk: IconVk,
  instagram: IconInstagram,
};

const socialIcons = computed<(IIconProps & { component: any })[]>(
  () =>
  socials.map((social) => ({
    ...social,
    component: iconsComponentsMap[social.sid],
  }))
);
</script>

<style lang="scss" scoped>
@import "./styles.scss";

footer {
  min-height: 50px;
}
</style>
